.packagesCard {
    border: 2px solid black;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
    padding:1rem;
    height:60vh;
    width:22vw;
    border-radius: 5px;
    img.packagesImage{
      display: flex;
      height:60%;
      width:90%;
      margin:auto;
      border-radius: 5px;
  }

  .appButton.tertiary {
    width: 80%;
    margin-left: 10%;
    margin-top: 1rem;
  }
}


@media only screen and (max-width: 480px) and (min-width: 320px)  {
  .packagesCard{
    width:70vw;
    height:50vh;
    margin: auto;
  }
 
}