@import '../../assets/sass/variables/index';

.appButton {
  // border-radius: 8px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding:0.25rem 0.875rem;
  cursor: pointer;
  border: 2px solid;
  white-space: nowrap;
  font-size: 16px;
  line-height: 29px;
  font-weight:600px;

  &.primary.borderless.grey {
    background-color: $grey6;
  }

  &.primary {
    background-color: $mono-white;
    border-color: $mono-black;
    color: $mono-black;
  }
  &.secondary {
   background-color: $mono-white;
   border-color: $mono-gold;
   color:$mono-gold;
   border:1px solid;
  }
  &.tertiary {
    background-color: $mono-white;
    border-color: $mono-blue;
    color: $mono-blue;
    border:1px solid;
  }
  &.additional {
    background-color: $mono-gold;
    border-color: $mono-gold;
    color: $mono-white;
    border-radius: 16px;
    padding: 4px 16px;

  }

  &[disabled] {
    background-color: $grey5;
    color: $mono-white;
    border: none;
  }
  &.outlined.primary {
    background-color: $mono-white;
    color: $brand-primary;
  }
  &.outlined.secondary {
    background-color: $mono-white;
    color: $brand-secondary;
  }
  &.outlined.tertiary {
    background-color: $mono-white;
    color: $state-error;
  }
  &.borderless.primary {
    background-color: $mono-white;
    color: $brand-primary;
    border: none;
    padding: 0;
    margin: 0;
  }
  &.borderless.secondary {
    background-color: $mono-white;
    color: $brand-secondary;
    border: none;
    padding: 0;
    margin: 0;
  }
  &.borderless.tertiary {
    background-color: $mono-white;
    color: $state-error;
    border: none;
    padding: 0;
    margin: 0;
  }
}
#remove-btn {
  color: $grey3;
  font-weight: 700;
}

.appButton-sm {
  @extend .appButton;
  width: 193px;
  height: 48px;
}
.appButton-nrm {
  @extend .appButton;
  width: 275px;
  height: 56px;
}
.appButton-md {
  @extend .appButton;
  width: 310px;
  height: 62px;
}
.appButton-lg {
  @extend .appButton;
  width: 344px;
  height: 68px;
}

@media (max-width: 1024px) {
  .appButton {
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    border: 2px solid;
    white-space: nowrap;
  }
}

@media (max-width: 500px) {
  .appButton {
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    border: 2px solid;
    white-space: nowrap;
  }
}
