.ideaCard{
    display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  height: 60vh;
  width: 22vw;
//   border-radius: 5px;
  img.ideaImage{
      border-radius: 50%;
     width:80%;
     height:70%;
     margin:auto;
    //   width:10%
  }
  .appButton.tertiary{
    width:80%;
    margin-left:10%;
    
}

}
@media only screen and (max-width: 480px) and (min-width: 320px)  {
    .ideaCard{
        width:70vw;
        height:50vh;
        margin: auto;
        
    }
}