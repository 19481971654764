.postsCard {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  height: 60vh;
  width: 22vw;
  border-radius: 5px;
  img.postsImage {
    display: flex;
    height: 60%;
    width: 90%;
    margin: auto;
    border-radius: 5px;
  }
  .appButton.tertiary {
    width: 50%;
    margin:auto;
    margin-top: 1rem;
    height:20;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px)  {
  .postsCard{
   width:70vw;
   height:50vh;
   margin: auto;
   
   img.postsImage {
    display: flex;
    height: 80%;
    width: 100%;
    margin: auto;
    border-radius: 5px;
  }
 
  }
 }
 
