.footer {
  background-image: url("https://s3.amazonaws.com/crowdriff-media/full/464fc8ddb12f6e656afb60b227894223f7985f0674e2cd70778db95c1bfcb355.jpg");
//   background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  justify-content: space-around;
  text-align: left;
  // width:100vw;
  .footer_link {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: #fff;
    margin-bottom: 6rem;
    cursor: pointer;
    
    .phoneIcon{
        height:70%;
        width:6%;
    }
    .mailIcon{
        height:70%;
        width:10%;
    }
    .socialMediaIcon{
        height:70%;
        width:10%;
    }
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .footer{
        text-align: left;
        flex-wrap: wrap;
        padding: 0;
    }
}