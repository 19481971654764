@import "../../assets/sass/variables/index";
.home {
  text-align: center;
  .packages {
    display: flex;
    justify-content: space-around;
    padding: 0% 8%;
    flex-wrap: wrap;
    row-gap: 2rem;
    margin-bottom: 1rem;
    .appButton.primary {
      margin-top: 2rem;
    }
  }
  section {
    background-color: #ededed;
    margin-top: 2rem;

    .offers {
      display: flex;
      justify-content: space-around;
      padding: 0% 8%;
      flex-wrap: wrap;
      row-gap: 2rem;
      background-color: #ededed;
    }
  }
  .subscription {
    input[type="text"] {
      margin: auto;
      border-radius: 16px;
      width: 50%;
      height: 8.5vh;
    }
    .subscribe-btn {
      // display: flex;
      justify-content: center;
      vertical-align: middle;
      padding: 0.25rem 0.875rem;
      cursor: pointer;
      border: 2px solid;
      white-space: nowrap;
      font-size: 16px;
      line-height: 29px;
      font-weight: 600px;
      background-color: $mono-black;
      border-color: $mono-black;
      color: $mono-white;
      border-radius: 16px;
      margin-left: -11%;
      width: 10%;
      height: 7vh;

      // left:-10%
    }
  }
  .idea {
    display: flex;
    justify-content: space-around;
    padding: 0% 8%;
    flex-wrap: wrap;
    row-gap: 2rem;
    margin-bottom: 1rem;
  }
  .sponsors {
    display: flex;
    margin: auto;
    justify-content: space-between;
    margin: 4% 14%;
    flex-wrap: wrap;
  }
}
.posts {
  display: flex;
  justify-content: space-around;
  padding: 0% 8%;
  flex-wrap: wrap;
  row-gap: 2rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .home {
    .packages {
      flex-direction: column;
      padding: 0% 2%;
    }
    .offers {
      flex-direction: column;
    }
    .subscription {
      input[type="text"] {
        margin: auto;
        border-radius: 16px;
        width: 80%;
        height: 6.5vh;
      }
      .subscribe-btn {
        // display: flex;
        justify-content: center;
        vertical-align: middle;
        padding: 0.25rem 0.875rem;
        cursor: pointer;
        border: 2px solid;
        white-space: nowrap;
        font-size: 16px;
        line-height: 29px;
        font-weight: 600px;
        background-color: $mono-black;
        border-color: $mono-black;
        color: $mono-white;
        border-radius: 16px;
        margin-left: -26%;
        width: 25%;
        height: 5.5vh;

        // left:-10%
      }
    }
    .idea {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 2rem;
      margin-bottom: 1rem;
    }
    .posts {
      flex-direction: column;
    }
    .sponsors {
      justify-content: center;
    }
  }
}
