.offersCard {
  border: 2px solid black;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  padding:1rem;
  height:28vw;
  width:22vw;
  border-radius: 5px;
  background-color: #fff;
  img.offersImage{
      display: flex;
      height:20%;
      width:20%;
      margin-left: 40%;
  }
  .appButton.tertiary{
      width:80%;
      margin-left:10%;
      
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px)  {
 .offersCard{
  width:70vw;
  height:50vh;
  margin: auto;

 }
}
