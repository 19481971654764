@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://db.onlinewebfonts.com/t/f2ecc6ef740fcf60de095b0b087dd58d.eot'); /* IE9*/
// @import url('https://db.onlinewebfonts.com/t/f2ecc6ef740fcf60de095b0b087dd58d.eot?#iefix'); /* IE6-IE8 */
// @import url('https://db.onlinewebfonts.com/t/f2ecc6ef740fcf60de095b0b087dd58d.woff2'); /* chrome firefox */
// @import url('https://db.onlinewebfonts.com/t/f2ecc6ef740fcf60de095b0b087dd58d.woff'); /* chrome firefox */
// @import url('https://db.onlinewebfonts.com/t/f2ecc6ef740fcf60de095b0b087dd58d.ttf'); /* chrome firefox opera Safari, Android, iOS 4.2+*/
// @import url('https://db.onlinewebfonts.com/t/f2ecc6ef740fcf60de095b0b087dd58d.svg#OCR A Extended'); /* iOS 4.1- */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'OCR A Std';
  font-style: normal;
  font-weight: normal;
  src: local('OCR A Std'), url('OCRAStd.woff') format('woff');
}
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova'), url('ProximaNovaRegular.woff') format('woff');
}

$font-stack: 'Lato', 'sans-serif';
$card-font-stack: 'OCR A Std', 'sans-serif';
$card_secondary-stack: 'Inter', 'sans-serif';
$card_tertiary_stack: 'Proxima nova', 'sans-serif';
$font-size: 16px;
h1 {
  font-size: 56px;
  line-height: 61.6px;
  font-weight: 700;
  white-space: nowrap;
}
h2 {
  font-size: 48px;
  line-height: 52.8px;
  font-weight: 700;
  white-space: nowrap;
}
h3 {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  white-space: nowrap;
}
h4 {
  font-size: 32px;
  line-height: 35.2px;
  font-weight: 700;
  white-space: nowrap;
}
h5 {
  font-size: 24px;
  line-height: 26.4px;
  font-weight: 700;
  white-space: nowrap;
}
h6 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  white-space: nowrap;
}

.lg-text-bold {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.lg-text-reg {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

.md-text-bold {
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 700;
}

.md-text-reg {
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 400;
}

.nrm-text-bold {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 700;
}

.nrm-text-reg {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
}

.sm-text-bold {
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 700;
}

.sm-text-reg {
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
}

.invalid-feedback-text {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: red;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
  }

  h4 {
    font-size: 20px;
    line-height: 20px;
  }

  // .lg-text-bold {
  //   font-size: 18px;
  //   line-height: 18px;
  //   font-weight: 700;
  // }
  // .lg-text-reg {
  //   font-size: 18px;
  //   line-height: 18px;
  //   font-weight: 400;
  // }
}

@media only screen and (max-width: $lg-device) {
  .lg-text-bold {
    font-size: 1rem;
    line-height: 1.5rem;
    // font-size: 24px;
    // line-height: 32px;
    font-weight: 700;
  }

  .lg-text-reg {
    font-size: 1rem;
    line-height: 1.25rem;
    // font-size: 24px;
    // line-height: 32px;
    font-weight: 400;
  }
  .md-text-bold {
    font-size: 0.8rem;
    line-height: 1.125rem;
    // font-size: 18px;
    // line-height: 25.2px;
    font-weight: 700;
  }

  .md-text-reg {
    font-size: 0.8rem;
    line-height: 1.125rem;
    // font-size: 18px;
    // line-height: 25.2px;
    font-weight: 400;
  }

  .nrm-text-bold {
    font-size: 0.75rem;
    line-height: 1rem;
    // font-size: 16px;
    // line-height: 22.4px;
    font-weight: 700;
  }

  .nrm-text-reg {
    font-size: 0.75rem;
    line-height: 1rem;
    // font-size: 16px;
    // line-height: 22.4px;
    font-weight: 400;
  }
}
//   h1 {
//     font-size: 24px;
//     line-height: 24px;
//     font-weight: 700;
//   }

//   h4 {
//     font-size: 12px;
//     line-height: 12px;
//   }

//   // .lg-text-bold {
//   //   font-size: 12px;
//   //   line-height: 18px;
//   //   font-weight: 700;
//   // }
//   // .lg-text-reg {
//   //   font-size: 12px;
//   //   line-height: 18px;
//   //   font-weight: 400;
//   // }
// }
